import React, { useState, useEffect } from "react";
import { Navigation } from "../../components/Navigation";
import "./style.css";
import { Button } from "../../components/Button";
import { FrameWrapper} from "../../components/FrameWrapper";
import { FrameWrapper_matched} from "../../components/FrameWrapper_matched";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom'
import { Loading } from '../../screens/Loading';
import { useNavigate } from 'react-router-dom'
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { RingScore } from "../../components/Ring"
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

export const New_suggested_edits = () => {
    //---------------------------------auhentication code----------------------------------------
    //auth0 component
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        return <div>Please log in to view your suggested edits.</div>;
    }
    // for real data
    const location = useLocation();
    data = location.state?.data;
    
    // for testing
    // const data = require('./data.json');
    const work_experience = data.original.work_experience;
    const proj_experience = data.original.project_experience;
    const revise_comments = data.revised_response;
    const combinedExperience = [
        ...work_experience.map((exp, index) => ({ ...exp, type: 'Work', id: `${index}`, score: `${revise_comments[index].relevancy_score}` })),
        ...proj_experience.map((exp, index) => ({ ...exp, type: 'Project', id: `${work_experience.length + index}`, score: `${revise_comments[work_experience.length + index].relevancy_score}`}))
      ];
    const company_analysis = data.company_analysis;
    const writting_mode = data.mode;
    const job_description = location.state?.job_description;
    const jobPosition = location.state?.jobPosition;
    const companyName = location.state?.companyName;
    const original_personal_data = {
        personal_info: data.original.basic_info,
        education: data.original.education,
        skills: data.original.skills,
        certificate: data.original.certificate
    };
    const [selections, setSelections] = useState({});
    const [loading, setLoading] = useState(false); // state for loading
    const [apiResponse, setApiResponse] = useState(null);
    const navigate = useNavigate();
    // State to hold the selected experience
    const [selectedExperience, setSelectedExperience] = useState(combinedExperience[0]);
    
    // Set checkboxes to be checked initially, except those in aspect6
    useEffect(() => {
        const initialSelections = {};
        work_experience.forEach((_, experienceIndex) => {
            Object.keys(revise_comments[experienceIndex]).forEach(aspectKey => {
                if (aspectKey === 'aspect6') {
                    // Initialize each item in aspect6 array as unchecked
                    revise_comments[experienceIndex][aspectKey].forEach((_, idx) => {
                        initialSelections[`experience-${experienceIndex}-${aspectKey}-${idx}`] = false;
                    });
                } else {
                    initialSelections[`experience-${experienceIndex}-${aspectKey}`] = true;
                }
            });
        });
        setSelections(prevSelections => ({ ...prevSelections, ...initialSelections }));
    }, [work_experience, revise_comments]);

    useEffect(() => {
        const initialSelections_project = {};
        proj_experience.forEach((_, experienceIndex) => {
            Object.keys(revise_comments[work_experience.length + experienceIndex]).forEach(aspectKey => {
                if (aspectKey === 'aspect6') {
                    // Initialize each item in aspect6 array as unchecked
                    revise_comments[work_experience.length + experienceIndex][aspectKey].forEach((_, idx) => {
                        initialSelections_project[`experience-${work_experience.length + experienceIndex}-${aspectKey}-${idx}`] = false;
                    });
                } else {
                    initialSelections_project[`experience-${work_experience.length + experienceIndex}-${aspectKey}`] = true;
                }
            });
        });
        setSelections(prevSelections => ({ ...prevSelections, ...initialSelections_project }));
    }, [proj_experience, revise_comments]);

    // Function to handle checkbox changes, including those in aspect6
    const handleCheckboxChange = (experienceIndex, aspectKey, idx = null) => {
        const key = idx === null
            ? `experience-${experienceIndex}-${aspectKey}`
            : `experience-${experienceIndex}-${aspectKey}-${idx}`;
        setSelections(prevSelections => ({
            ...prevSelections,
            [key]: !prevSelections[key]
        }));
    };

    const getAspectTitle = (aspectKey) => {
        const aspectTitles = {
            'aspect1': 'Metric',
            'aspect2': 'Technical Skills',
            'aspect3': 'Verbs',
            'aspect4': 'Impact',
            'aspect5': 'Soft Skills',
            // Add other aspects if necessary
        };
        return aspectTitles[aspectKey] || aspectKey; // Fallback to aspectKey if no title is found
    };

    const renderAspectContent = (aspectKey, experienceIndex) => {
        const comments = revise_comments[experienceIndex][aspectKey];
        switch (aspectKey) {
            case 'aspect1':
                return (
                    <>
                        We suggest adding a metric to the experience to better match the job description:<br /><br />
                        {comments}
                    </>
                );
            case 'aspect2':
                return (
                    <>
                        {/* This experience aligns with the following keywords:<br /> {comments.matched}.<br /><br />
                        However, the following keywords in the job description are missing and are highly relevant to your experience:
                        <br /> {comments.not_matched}.<br /><br />
                        We will strive to incorporate these keywords into your resume. */}
                        {comments.matched}{comments.not_matched}
                        
                    </>
                );
            case 'aspect4':
                return (
                    <>
                        We recommend adding an achievement to the experience to demonstrate your impact and align with the job description:<br /><br />
                        {comments}
                    </>
                );
            // Add cases for other aspects as necessary
            default:
                return comments; // Default case for simple string comments or other aspect types
        }
    };

    const keywords_match = revise_comments[selectedExperience.id] &&
                       revise_comments[selectedExperience.id]['aspect2'] &&
                       revise_comments[selectedExperience.id]['aspect2'].matched
                       ? revise_comments[selectedExperience.id]['aspect2'].matched
                       : [];

    const keywords_not_match = revise_comments[selectedExperience.id] &&
                            revise_comments[selectedExperience.id]['aspect2'] &&
                            revise_comments[selectedExperience.id]['aspect2'].not_matched
                            ? revise_comments[selectedExperience.id]['aspect2'].not_matched
                            : [];


    const logCurrentSelections = () => {
        console.log('Current selections:', JSON.stringify(selections));
    };

    // Handler for clicking an experience button
    const handleExperienceClick = (experience) => {
        setSelectedExperience(experience);
    };

    const sendToServer = async () => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
          }
        //---------------------------------auhentication code----------------------------------------
        // create a FormData object
        const formData = new FormData();
        // append the required form data that will be sent to the server
        formData.append('user_selections', JSON.stringify(selections));
        formData.append('comments', JSON.stringify(data.revised_response));
        formData.append('jd_key_words', JSON.stringify(data.jd_key_words));
        formData.append('resume_existed_skills', JSON.stringify(original_personal_data.skills));
        formData.append('work_experience', JSON.stringify(data.original.work_experience));
        formData.append('project_experience', JSON.stringify(data.original.project_experience));
        formData.append('record_comments', JSON.stringify(data));
        formData.append('mode', writting_mode);
        formData.append('job_description', job_description);
        formData.append('jobPosition', jobPosition);
        //start loading
        setLoading(true);
        // send a POST request
        console.log('Send POST request to API');
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/revise_exp_comments', { //api endpoint
          method: 'POST',
          //---------------------------------auhentication code----------------------------------------
          headers: {
            Authorization: `Bearer ${token}`
          },
          //---------------------------------auhentication code----------------------------------------
          body: formData
        }).then(response => {
          // Check for the 400 status code 
          // console.log('Server Response:', response);
          if (response.status === 400) {
            throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
          }
          if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
          }
          //---------------------------------auhentication code----------------------------------------
          if (response.status == 401) {
            throw new Error('Sorry, you must login first to upload your resume')
          }
          //---------------------------------auhentication code----------------------------------------
          return response.json();
        }).then(data => {
          // handle the response data
          //console.log(data);
          //update setapiresponse that is used in result.js
          setApiResponse(data);
          //stop loading and redirect to /result
          setLoading(false);
          //history.push("/result");
        }).catch(error => {
          // handle the error
          console.error(error);
          // If there's an error, ensure loading is turned off
          setLoading(false);
    
          alert(error.message);  // Displaying the error message to the user
          window.location.href = '/DashboardRevision';
        });
      }

      if (loading) {

        return <Loading />;
    
      } else if (apiResponse) {
          //combine the jd_keywords & skills extrated from the jd
          //const combined_skills = [...new Set([...(original_personal_data.skills || []), ...(apiResponse.filtered_skills || [])])];
          //the new version is the filtered skills for the skills section in the resume
          const combined_skills = (apiResponse.filtered_skills || []);
          original_personal_data.skills = combined_skills;
          let currentSectionVar = 'experiences';
          if (apiResponse.rendering_data.work.length === 0) {
            currentSectionVar = 'projects';
          }
          navigate('/ResumeResults', { state: { data: apiResponse, 
                                                job_description: job_description, 
                                                company_analysis: company_analysis, 
                                                original_data: original_personal_data, 
                                                mode: writting_mode, 
                                                executive_summary: null, 
                                                selectedExperienceIndex: 0,
                                                resetExpCopy: apiResponse.rendering_data,
                                                jd_keywords: JSON.parse(JSON.stringify(data.jd_key_words)),
                                                isSkillsCategorized: false,
                                                categorizedSkills: null,
                                                currentSection: currentSectionVar,
                                                jobPosition: jobPosition,
                                                companyName: companyName
                                            } });

      }


  return (
    <div className="suggested-page">
      <Helmet>
        {/* <!-- Google tag (gtag.js) --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-F812QPTDD9');
          `}
        </script>
        <title>Comments - ResuMaster</title>
        <meta property="og:title" content="Comments - ResuMaster" />
        <body className="result_page" />
         <script src="//embed.typeform.com/next/embed.js"></script>
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <div class="scanning_title">
        Analysis
      </div>
      
      <div class="common-block-container">
        <div class="company-analysis-heading">
            <img src="/img/edits_company.png" alt="Icon" style={{ marginRight: '8px' }} />
            <span>Company Analysis</span>
        </div>

        {company_analysis !== "null" ? (
            <p className="company-analysis-content">{company_analysis}</p>
            ) : (
            <p className="company-analysis-content">No analysis available.</p>
        )}
      </div>

      <div class="common-block-container">
        <div class="company-analysis-heading">
            <img src="/img/edits_analysis.png" alt="Icon" style={{ marginRight: '8px' }} />
            <span>Resume Analysis</span>
        </div>

        <div className="navigation-bar">
            {combinedExperience.map((experience, index) => (
                <button
                    key={experience.id} // use unique ID
                    onClick={() => handleExperienceClick(experience)}
                    className={selectedExperience && selectedExperience.id === experience.id ? 'active' : ''}
                >
                    {experience.type} Experience {experience.type === 'Work' ?
                    `${index + 1}` :
                    `${index - work_experience.length + 1}`}
                </button>
            ))}
        </div>
        {selectedExperience && (
            <div className="experience-details">
                <div className="score-container">
                    <RingScore score={selectedExperience.score} />
                    <div className="text-container">
                        <div className="matching-score-text">Matching Score</div>
                        {
                            selectedExperience.score > 80 ?
                            <div className="additional-text">
                                Almost perfect! Your resume is highly aligned with the job description. Just a couple of fine-tuning touches and you are good to go!
                            </div> : 
                            selectedExperience.score > 50 ?
                            <div className="additional-text">
                                You are getting there! Your resume matches quite well. A few more tweaks will help you stand out even more.
                            </div> :
                            <div className="additional-text">
                                Making progress! Your resume has some relevant points but needs more targeted adjustments to catch the employer's eye. Please see below for our suggestions!
                            </div>

                        }
                    </div>
                </div>


                <div class="current-experience-container">
                    {
                        selectedExperience.type == 'Work' ?
                        <div>
                            <div class="experience_title">
                                {selectedExperience.job_title}
                                {selectedExperience.company && ` at ${selectedExperience.company}`}
                            </div>
                            <div class="experience_duration">
                                {selectedExperience.duration ? selectedExperience.duration : ''}
                                {selectedExperience.location && `, ${selectedExperience.location}`}
                            </div>
                            {selectedExperience.job_summary.map((summary, idx) => (
                                <li class="experience-description-list-item" key={idx}>{summary}</li>
                            ))}
                        </div>
                        :
                        <div>
                            <div class="experience_title">
                                {selectedExperience.project_name}
                                {selectedExperience.institution && ` at ${selectedExperience.institution}`}
                            </div>
                            <div class="experience_duration">
                                {selectedExperience.duration ? selectedExperience.duration : ''}
                                {selectedExperience.location && ` ${selectedExperience.location}`}
                            </div>
                            {selectedExperience.project_description.map((summary, idx) => (
                                <li class="experience-description-list-item" key={idx}>{summary}</li>
                            ))}
                        </div>
                    }
                </div>

                
                <div className="experience_analysis_container">
                    <div className="analysis_keywords">
                        <div className="analysis_keywords_heading">
                            <img src="/img/edits_keywords.png" alt="Icon"/>
                            <span>Keywords</span>
                        </div>
                        <div className="analysis_keywords_content">
                            
                            {keywords_match.map((keyword, index) => (
                                <FrameWrapper_matched key={index} className="keywords_matched" text={keyword} />
                            ))}
                            {keywords_not_match.map((keyword, index) => (
                                <FrameWrapper key={index} className="keywords_unmatched" text={keyword} />
                            ))}
                        </div>
                    </div>
                    <hr className="flex-line" />
                    <div className="analysis_soft_skills">
                        <div className="analysis_soft_skills_heading">
                            <img src="/img/edits_skills.png" alt="Icon"/>
                            <span>Soft Skills</span>
                        </div>
                        <div className="analysis_soft_skills_content">
                        {renderAspectContent('aspect5', selectedExperience.id)}
                        </div>
                    </div>
                    <hr className="flex-line" />
                    <div className="analysis_verbs">
                        <div className="analysis_verbs_heading">
                            <img src="/img/edits_verbs.png" alt="Icon"/>
                            <span>Action Verbs</span>
                        </div>
                        <div className="analysis_verbs_content">
                        {renderAspectContent('aspect3', selectedExperience.id)}
                        </div>
                    </div>
                    <hr className="flex-line" />
                    <div className="analysis_bullet_points">
                        <div className="analysis_bullet_points_heading">
                            <img src="/img/edits_ai.png" alt="Icon"/>
                            <span>AI Suggested Bullet Points</span>
                        </div>
                        <p>Check any bullet point you would like to include in the revision</p>
                        <div className="analysis_bullet_points_content">
                            <div className="bullet_points_content">
                                {revise_comments[selectedExperience.id]['aspect6'].map((comment, idx) => (
                                    <label key={idx} className={`bullet_point1 ${selections[`experience-${selectedExperience.id}-aspect6-${idx}`] ? 'checked' : ''}`} >
                                        <input 
                                            type="checkbox"
                                            checked={selections[`experience-${selectedExperience.id}-aspect6-${idx}`]}
                                            onChange={() => handleCheckboxChange(selectedExperience.id, 'aspect6', idx)}
                                            className="checkbox_aspect_6"
                                        />
                                        <span className="checkmark">{comment}</span>
                                    </label>
                                ))}

                            </div>
                        </div>
                    </div>
                    
                </div>
                
                {/* <div class="suggests-grid-container">
                    {Object.keys(revise_comments[selectedExperience.id]).map((aspectKey) => {
                            const aspectTitle = getAspectTitle(aspectKey);
                            const aspectContent = renderAspectContent(aspectKey, selectedExperience.id);
                            if (aspectKey === 'aspect6') {
                                return (
                                    <div className="side-card" key={aspectKey}>
                                        <div className="side-card-text">
                                            <div class="added_description">Suggested bullet points (check to add)</div>
                                            {revise_comments[selectedExperience.id]['aspect6'].map((comment, idx) => (
                                                <label key={idx} className="checkbox-container">
                                                    <input 
                                                        type="checkbox"
                                                        checked={selections[`experience-${selectedExperience.id}-aspect6-${idx}`]}
                                                        onChange={() => handleCheckboxChange(selectedExperience.id, 'aspect6', idx)}
                                                    />
                                                    <span className="checkmark">{comment}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                )
                            } else if (aspectKey === 'relevancy_score') {
                            } else {
                                return (
                                    <div className="side-card" key={aspectKey}>
                                        <label className="side-card-checkbox">
                                            <input 
                                                type="checkbox"
                                                checked={selections[`experience-${selectedExperience.id}-${aspectKey}`]}
                                                onChange={() => handleCheckboxChange(selectedExperience.id, aspectKey)}
                                            />
                                            <span className="side-card-header">{aspectTitle}</span>
                                        </label>
                                        <div className="side-card-text">
                                            {aspectContent}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                </div> */}
            </div>
        )}

      </div>

      <div className="button-container">
        <Button className="suggested-edits-result-button" onClick={sendToServer} text="Continue to revision" />
      </div>
      


      
      
    </div>
  );
};
