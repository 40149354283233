import React, { useState } from "react";
import { Navigation } from "../../components/Navigation";
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import "./style.css";
import { Button } from "../../components/Button";
import { jsPDF } from 'jspdf';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';

export const ClResults = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  if (!isAuthenticated) {
    return <div>Please log in to check your coverletter.</div>;
  }

  const location = useLocation();
  const data = location.state?.data;
  const initialCoverletter = data.cover_letter;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // state for loading
  const [name, setName] = useState(data.name);
  const [email, setEmail] = useState(data.email);
  const [phone, setPhone] = useState(data.phone_number);
  const [personal_web, setPersonalWeb] = useState(data.personal_website_email);
  const [company, setCompany] = useState(data.company);

  const [coverletter, setCoverletter] = useState(initialCoverletter);

  const [jobPosition, setJobPosition] = useState(location.state?.jobPosition);

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(coverletter);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Error copying text: ", err);
    }
  };

  const exportStringToPDF = (content) => {
    const doc = new jsPDF({
      format: 'letter',
    });

    const marginLeft = 20;
    const marginRight = 20;
    const marginTop = 20;
    const marginBottom = 20;
    const pageWidth = doc.internal.pageSize.width;
    const maxContentWidth = pageWidth - marginLeft - marginRight;

    // Add Name section
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text(name, marginLeft, marginTop);

    // Add some space
    let y = marginTop + 7;

    // Add Contacts section
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");

    let contactInfo = [];
    if (email) contactInfo.push(`• ${email}`);
    if (phone) contactInfo.push(`• ${phone}`);
    if (personal_web) contactInfo.push(`• ${personal_web}`);

    let contactLine = '';
    contactInfo.forEach(info => {
      if (doc.getTextWidth(contactLine + info) > maxContentWidth) {
        doc.text(contactLine.trim(), marginLeft, y);
        y += 6;
        contactLine = info + '   ';
      } else {
        contactLine += info + '   ';
      }
    });
    if (contactLine.trim()) {
      doc.text(contactLine.trim(), marginLeft, y);
      y += 6;
    }

    // Add some space
    y += 10;

    // Add Company section
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(company, marginLeft, y);
    y += 10;

    // Add Cover Letter content
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    const lines = doc.splitTextToSize(content, maxContentWidth);

    const lineHeight = 5;
    const pageHeight = doc.internal.pageSize.height;
    const maxPageHeight = pageHeight - marginBottom;

    lines.forEach(line => {
      if (y + lineHeight > maxPageHeight) {
        doc.addPage();
        y = marginTop;
      }
      doc.text(line, marginLeft, y);
      y += lineHeight * 1.15;
    });

    let jobPositionPart = jobPosition ? `_${jobPosition}` : '';
    let companyPart = company ? `_${company}` : '';

    doc.save(`${name}${jobPositionPart}${companyPart}_cover_letter.pdf`);
  };

  const sendToServer_db = async (navigateTo) => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return;
    }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    setLoading(true);
    // create a FormData object
    const coverletter_obj = {
        name: name,
        email: email,
        phone: phone,
        personal_web: personal_web,
        company: company,
        jobPosition: jobPosition,
        coverletter: coverletter
    };
    const formData = new FormData();
    // append the file and the job description
    formData.append('coverletter_json_str', JSON.stringify(coverletter_obj));

    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/save_coverletter', { //api endpoint
        method: 'POST',
        //---------------------------------auhentication code----------------------------------------
        headers: {
            Authorization: `Bearer ${token}`
        },
        //---------------------------------auhentication code----------------------------------------
        body: formData
    }).then(response => {
        // Check for the 400 status code 
        // console.log('Server Response:', response);
        if (response.status === 400) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
        }
        if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
        }
        //---------------------------------auhentication code----------------------------------------
        if (response.status == 401) {
            throw new Error('Sorry, you must login first')
        }
        //---------------------------------auhentication code----------------------------------------
        setLoading(false);
        navigate(navigateTo);
    }).catch(error => {
        // handle the error
        console.error(error);
        // If there's an error, ensure loading is turned off
        setLoading(false);

        alert(error.message);  // Displaying the error message to the user
    });
};

  const handleNameChange = (new_name) => {
    setName(new_name);
  };

  const handleEmailChange = (new_email) => {
    setEmail(new_email);
  };

  const handlePhoneChange = (new_phone) => {
    setPhone(new_phone);
  };

  const handleURLChange = (new_url) => {
    setPersonalWeb(new_url)
  };

  return (
    <div className="ClResults">
      <Helmet>
        {/* <!-- Google tag (gtag.js) --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-F812QPTDD9');
          `}
        </script>
        <title>Coverletter Results - ResuMaster</title>
        <meta property="og:title" content="Coverletter Results - ResuMaster" />
      </Helmet>
      {isAuthenticated ? (
        <Navigation_loggedin className="navigation-2202" />
      ) : (
        <Navigation className="navigation-2202" />
      )}
      <div className="ClResults">
        <Link to="/" className="back-button">
          <img className="back-button-img" src='/img/chevron-left.png' />
          <div className="back-button-text">Return to Home</div>
        </Link>
        <div className="ClResults-main">
          <div className="credits-used-row">
            <div className="credits-used">-50 ResuCredits</div>
          </div>
          <div className="title">
            <div className="title-2">View your cover letter</div>
            <Button
              className="export-button"
              onClick={() => exportStringToPDF(coverletter)}
              text="Export"
            />
          </div>
          <div className="resumeContent">
            <label className="form-label">Name*</label>
            <input
                type="text"
                className="experience-form-input"
                value={name}
                onChange={(e) => handleNameChange(e.target.value)}
            />

            <label className="form-label">Email</label>
            <input
                type="text"
                className="experience-form-input"
                value={email}
                onChange={(e) => handleEmailChange(e.target.value)}
            />

            <label className="form-label">Phone Number</label>
            <input
                type="text"
                className="experience-form-input"
                value={phone}
                onChange={(e) => handlePhoneChange(e.target.value)}
            />
            
            <label className="form-label">Linkedin/Personal Website URL</label>
            <input
                type="text"
                className="experience-form-input"
                value={personal_web}
                onChange={(e) => handleURLChange(e.target.value)}
            />

            <div className="img-box">
              <img className="copy-img"
                alt="Copy"
                src="/img/copy.png"
                onClick={() => handleCopyToClipboard()}
              />
            </div>
            <textarea
              className="coverletter-textarea"
              value={coverletter}
              onChange={(e) => setCoverletter(e.target.value)}
            />
          </div>
          <Button
              className="export-button"
              onClick={() => sendToServer_db('/Dashboard')}
              text="Save to Profile"
            />
        </div>
      </div>
    </div>
  );
};
