import React, { useCallback, useState } from 'react'
import { Navigation } from "../../components/Navigation";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";  // <-- Import useAuth0 hook
import { useNavigate } from 'react-router-dom';
import { Loading_mainpage } from '../../screens/Loading';
import CurrentResume from '../CurrentResume/CurrentResume'
import "./style_board.css";

const Dashboard = () => {

    //---------------------------------auhentication code----------------------------------------
    const { getAccessTokenSilently, isAuthenticated, loginWithRedirect, logout, user, isLoading } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        return <div>Please log in first to start revise your resume.</div>;
    }
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('My Profile');
    const [revisionHistory, setRevisionHistory] = useState(null);
    const [historyNameInfo, setHistoryNameInfo] = useState(null);
    const [coverletterHistoryNameInfo, setCoverletterHistoryNameInfo] = useState(null);
    // console.log(revisionHistory);
    // console.log(historyNameInfo);
    // console.log(coverletterHistoryNameInfo);
    const [letterHistory, setLetterHistory] = useState(null);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [credits, setCredits] = useState(" "); // Initialize credits state
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isModal1Open, setIsModal1Open] = useState(false);
    const [isModal2Open, setIsModal2Open] = useState(false);
    const [subscriptionEnd, setSubscriptionEnd] = useState(" ");
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentResume, setCurrentResume] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const displayedItems = showAll ? paymentHistory : paymentHistory.slice(0, 2);
    // console.log(testingData.Record_content)
    // console.log(letterHistory)
    const [resumeRemoved, setResumeRemoved] = useState(false);
    // console.log(testingData.Record_content)
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);  // Start loading
            try {
                if (isAuthenticated) {
                    await fetchRevisionHistory();
                    await fetchPaymentHistory();
                    await fetchUserCredits();
                    await fetchCurrentResume();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);  // Stop loading after data is fetched or an error occurs
            }
        };
    
        fetchData();
    }, [isAuthenticated, resumeRemoved]);


    const getHistoryName = (historyNameArray) => {
        let historyNameInfoArrary = [];
    
        historyNameArray.forEach(element => {
            // Parse each element
            let parsedElement = JSON.parse(element);
            
            // Check if namingInfo exists and parse it, otherwise push the default object, this require the user fill in the company name & job position title
            if (parsedElement.namingInfo) {
                let parsedNamingInfo = JSON.parse(JSON.stringify(parsedElement.namingInfo));
                historyNameInfoArrary.push(parsedNamingInfo);
            } else {
                historyNameInfoArrary.push(null);
            }
        });
    
        return historyNameInfoArrary;
    };

    const getCoverletterHistoryName = (coverletterHistoryArray) => {
        let historyNameInfoArrary = [];
    
        coverletterHistoryArray.forEach(element => {
            // Parse each element
            let parsedElement = JSON.parse(element);
            
            // Check if namingInfo exists and parse it, otherwise push the default object
            if (parsedElement.jobPosition) {
                let clJobPosition = parsedElement.jobPosition;
                let clCompany = parsedElement.company;
                let nameRecord = {
                    "jobPosition": clJobPosition,
                    "companyName": clCompany
                };
                historyNameInfoArrary.push(nameRecord);
            } else {
                historyNameInfoArrary.push(null);
            }
        });
    
        return historyNameInfoArrary;
    };

    const fetchRevisionHistory = async () => {
        
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
        }
        const token = await getAccessTokenSilently();
        
        fetch('/resumasterapi/fetch_resume_history', { //api endpoint
            method: 'POST',
           
            headers: {
                Authorization: `Bearer ${token}`
            },
           
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            
            return response.json();
        }).then(data => {
            setRevisionHistory(JSON.parse(JSON.stringify(data)));
            setLetterHistory(data.coverletter_history);
            setHistoryNameInfo(getHistoryName(data.results));
            setCoverletterHistoryNameInfo(getCoverletterHistoryName(data.coverletter_history.content_history));
           
           
        }).catch(error => {
            
            console.error(error);
         
            // setLoading(false);
            alert(error.message);  
        });
    }

    useEffect(() => {
        if (isSubscribed === true) {
            fetchUserSubscription();
        }
    }, [isSubscribed]);


    const fetchUserCredits = async () => {
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/check_user_status', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            if (data.in_subscription === true) {
                setCredits("Monthly Subscription");
                setIsSubscribed(true);
                //console.log('credits:Monthly Subscription ')
            }
            else {
                setCredits(data.current_credits);
                //console.log(data.current_credits);
                //console.log('credits:',credits)
            }
            //setLoading(false);
        }).catch(error => {
            // handle the error
            console.error("fetch user credits failed:", error);
            // If there's an error, ensure loading is turned off
            // Check if the error is due to a JSON parsing issue
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
                // You might want to set a different state here to handle this specific error
            }
            //setLoading(false);
            alert(error.message);  // Displaying the error message to the user
        });
    }

    const fetchUserSubscription = async () => {
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/get_subscription_info', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            setSubscriptionEnd(data.current_period_end);
            if (data.cancel_at_period_end === true) {
                setSubscriptionCancelled(true);
                setIsSubscribed(false);
            }
            //setLoading(false);
        }).catch(error => {
            // handle the error
            console.error("fetch user credits failed:", error);
            // If there's an error, ensure loading is turned off
            // Check if the error is due to a JSON parsing issue
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
                // You might want to set a different state here to handle this specific error
            }
            //setLoading(false);
            alert(error.message);  // Displaying the error message to the user
        });
    }



    const fetchPaymentHistory = async () => {
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/check_payment_history', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            if (data && data.payment_info) {
                setPaymentHistory(data.payment_info);
                
            }
            //update setapiresponse that is used in result.js
            //setApiResponse(data);
            //stop loading and redirect to /result
            //setLoading(false);
            //history.push("/result");
        }).catch(error => {
            // handle the error
            console.error("fetch payment history failed:", error);
            // If there's an error, ensure loading is turned off
            // Check if the error is due to a JSON parsing issue
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
                // You might want to set a different state here to handle this specific error
            }
            //setLoading(false);
            alert(error.message);  // Displaying the error message to the user
        });
    };

    const fetchCurrentResume = async() => {
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/get_dashboard_resume', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            if (data) {
                setCurrentResume(data);
            }
        }).catch(error => {
            // handle the error
            console.error("fetch payment history failed:", error);
            // If there's an error, ensure loading is turned off
            // Check if the error is due to a JSON parsing issue
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
                // You might want to set a different state here to handle this specific error
            }
            alert(error.message);
        });
    };

    const handleRemoveCurrentResume = async() => {

        console.log('Delete Resume');
        closeModal2();
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/remove_dashboard_resume', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            setResumeRemoved(!resumeRemoved);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
        }).catch(error => {
            // handle the error
            console.error("remove current resume failed:", error);
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
            }
            alert(error.message);
        });
    }

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        window.scrollTo(0, 0);
    };
    const handleNewRevision = () => {
        if (currentResume && currentResume.Record_exist) {
            navigate('/DashboardRevision');
        } else {
            navigate('/uploadResume');
        }
        
    };
    // Function to handle subscription cancellation
    const handleCancelSubscription = async () => {
        console.log('Cancel subscription');
        closeModal1();

        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/cancel-subscription', { //api endpoint
        method: 'POST',
        //---------------------------------auhentication code----------------------------------------
        headers: {
            Authorization: `Bearer ${token}`
        },
        //---------------------------------auhentication code----------------------------------------
        }).then(response => {
        // Check for the 400 status code 
        // console.log('Server Response:', response);
        if (response.status === 400) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
        }
        if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
        }
        //---------------------------------auhentication code----------------------------------------
        if (response.status == 401) {
            throw new Error('Sorry, you must login first')
        }
        //---------------------------------auhentication code----------------------------------------
        return response.json();
        }).then(data => {
        if(data.status == "success"){
            setSubscriptionCancelled(true);
            setIsSubscribed(false);
        }
        }).catch(error => {
        // handle the error
        console.error("handle subscription cancel failed:", error);
        if (error.message.includes('Unexpected token')) {
            console.error('Received non-JSON response:', error);
            // You might want to set a different state here to handle this specific error
        }
        //setLoading(false);
        alert(error.message);  // Displaying the error message to the user
        });
    };

    // cancel modal
    const openModal1 = () => {
        setIsModal1Open(true);
        document.body.style.overflow = "hidden";
      };
  
    const closeModal1 = () => {
          setIsModal1Open(false);
          document.body.style.overflow = "";
      };

    useEffect(() => {
        return () => {
            document.body.style.overflow = ""; // Restore scrolling
          };
    }, [isModal1Open]);


    // delete modal
    const openModal2 = () => {
        setIsModal2Open(true);
        document.body.style.overflow = "hidden";
      };
  
    const closeModal2 = () => {
          setIsModal2Open(false);
          document.body.style.overflow = "";
      };

    useEffect(() => {
        return () => {
            document.body.style.overflow = ""; // Restore scrolling
          };
    }, [isModal2Open]);

    const handleClick = (data, index) => {
        // Navigate to /home and pass state
        navigate('/new_resume_revision_history', { state: { data, index } });
    };

    const handleClick_coverletter = (data, index) => {
        // Navigate to /home and pass state
        navigate('/coverletter_history', { state: { data, index } });
    };

    if (loading) {    
        return <Loading_mainpage />;
    }

    return (
        isAuthenticated ? (
            <div className="dashboard-results">
                <Helmet>
                    {/* <!-- Google tag (gtag.js) --> */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
                    <script>
                    {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'G-F812QPTDD9');
                    `}
                    </script>
                    <title>Dashboard - ResuMaster</title>
                    <meta property="og:title" content="Dashboard - ResuMaster" />
                </Helmet>
                {
                    isAuthenticated ? (
                        <Navigation_loggedin className="navigation-2202" />
                    ) : (
                        <Navigation className="navigation-2202" />
                    )
                }
                <div className="announcement-banner">
                    <div className="banner-text">
                    Explore your new personal dashboard! It enables you to save your resume for quick and efficient revisions.
                    </div>
                </div>

                <div className="db_contents">

                <div className="db_sidebar">
                    <div className="dashboard-upper-buttons">
                        <span className='username'>Hi,{user.name}</span>
                        <div className="upload_resume">
                            <button className='upload_btn'
                                onClick={handleNewRevision}
                            >{currentResume && currentResume.Record_exist ? 'Start New Revision' : 'Upload New Resume'}</button>
                        </div >
                        <div className="db_side-list">
                            <button
                                    onClick={() => handleOptionChange('My Profile')}
                                    className={selectedOption === 'My Profile' ? 'selected-button' : ''}
                            >
                            <img className='my-profile'
                                        alt="My Resume Icon"
                                        src={selectedOption === 'My Profile' ? "/img/my_resume_2.png" : "/img/my_resume_1.png"} />
                                    My Resume
                            </button>

                            <button
                                    onClick={() => handleOptionChange('Revision History')} 
                                    className={selectedOption === 'Revision History' ? 'selected-button' : ''}
                            >
                                <img 
                                    className='revision-history'
                                    alt="Revision History Icon" 
                                    src={selectedOption === 'Revision History' ? "/img/revision_history_2.png" : "/img/revision_history_1.png"} />
                                    Revision History
                            </button>
                             <button
                                    onClick={() => handleOptionChange('Billing History')}
                                    className={selectedOption === 'Billing History' ? 'selected-button' : ''}
                            >
                                    <img 
                                    className='billing-history'
                                    alt="Billing History Icon" src={selectedOption === 'Billing History' ? "/img/billing_icon_2.png" : "/img/billing_icon_1.png"} />
                                    Billing
                            </button>
                        </div>
                    </div>
                        <div className="dashboard-bottom-buttons">
                            <p className='useremail'>{user.email}</p>

                            <button
                                 className='logout'
                                onClick={logout}
                            >
                                    
                            <img alt="Logout Icon" src="/img/dashboard_logout.png"  />
                                    Logout
                            </button>
                            
                        </div>
                </div>

                < div className="db_main-box">
                        {selectedOption === 'My Profile' && (
                            <div className="m_resume">
                                <h4 >My Resume</h4>
                                {currentResume && currentResume.Record_exist ? (
                                    <div className='currentData'>
                                        <CurrentResume data={currentResume.Record_content} removeResumeHandler={openModal2}/>
                                    </div>
                                
                                ):(
                                    <div className='blank_box'>
                                        <img src="/img/resume_empty.png" alt="resume empty icon" />
                                        <p>It looks like you haven't uploaded any resumes yet. To get started,upload your resume and begin tailoring it to your job applications. </p>
                                        <button onClick={handleNewRevision}>Upload Resume</button>
                                    </div>

                                )}
                                <div className={`dimmer-delete ${isModal2Open ? 'active' : ''}`}></div>
                                {isModal2Open && (
                                <div className="delete-resume-modal-profile">
                                  {console.log("Modal2 is now open")} {/* Console log here */}
                                  <div className="delete-resume-modal-profile-content">
                                    <img className='confirmation-icon'
                                        alt="confirmation logo icon"
                                        src="/img/logo-purple.png" />
                                      <h2>Delete Profile?</h2>
                                      <p>Your resume and profile information will be deleted. 
                                        This is a permanent action and cannot be undone. </p>
                                      <button className="delete-resume-confirm-button-yes" onClick={handleRemoveCurrentResume}>Yes</button>
                                      <button className="delete-resume-confirm-button-no" onClick={closeModal2}>No</button>
                                  </div>
                                </div>
                                )}

                            </div>
                        )}
                        {selectedOption === 'Revision History' && (
                            <div className="re_history">
                                <h4>Revision History</h4>
                                {(revisionHistory?.time_stamp?.length > 0 || letterHistory?.time_stamp?.length > 0) ? (
                                    <div className='reviHistory'>
                                        <p>Your most recent revisions will be saved here. </p>
                                        {revisionHistory.time_stamp.length > 0 && (
                                        <div className='revi_list'>
                                            {revisionHistory.time_stamp.map((history, index) => (
                                                <div className="revi-item" key={index}
                                                onClick={(e) => {
                                                    e.preventDefault(); // Prevent default anchor behavior
                                                    handleClick(revisionHistory, index);
                                                }}>
                                                    <div className="history-resume-preview"></div>
                                                    <div className="text">
                                                    <p>
                                                        {historyNameInfo && historyNameInfo[index] ? (
                                                            historyNameInfo[index].jobPosition && historyNameInfo[index].companyName
                                                            ? `Resume for ${historyNameInfo[index].jobPosition} at ${historyNameInfo[index].companyName}`
                                                            : historyNameInfo[index].jobPosition
                                                            ? `Resume for ${historyNameInfo[index].jobPosition}`
                                                            : historyNameInfo[index].companyName
                                                            ? `Resume for ${historyNameInfo[index].companyName}`
                                                            : 'Resume Revision'
                                                        ) : 'Resume Revision'}
                                                    </p>
                                                        <p>Edited on {new Date(history).toLocaleDateString()}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        )}
                                        <div className={`revi_list letterHistory ${revisionHistory.time_stamp.length === 0 ? 'move-up' : ''}`}>
                                            {letterHistory && letterHistory.time_stamp.map((history, index) => (
                                                <div className="revi-item" key={index}
                                                onClick={(e) => {
                                                    e.preventDefault(); // Prevent default anchor behavior
                                                    handleClick_coverletter(letterHistory, index);
                                                }}>
                                                    <div className="history-cl-preview"></div>
                                                    <div className="text">
                                                        <p >
                                                        {coverletterHistoryNameInfo && coverletterHistoryNameInfo[index] && 
                                                            coverletterHistoryNameInfo[index].jobPosition && 
                                                            coverletterHistoryNameInfo[index].companyName ? 
                                                            `Coverletter for ${coverletterHistoryNameInfo[index].jobPosition} at ${coverletterHistoryNameInfo[index].companyName}` : 
                                                            'Coverletter Record'}
                                                        </p>
                                                        <p>Edited on {new Date(history).toLocaleDateString()}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                    
                                ) : (
                                    <div className='blank_box'>
                                        <img src="/img/history_empty.png" alt="history empty icon" />
                                        <p>It looks like you haven't made any changes yet. To get started,upload your resume first and begin tailoring it to your job applications. </p>
                                    </div>
                                )}
                                

                            </div>
                        )}
                        
                        {selectedOption === 'Billing History' && (
                            <div id="bill_history">
                                <h4>Billing</h4>
                                {paymentHistory && (
                                    <div id='resuHistory'>
                                        <div className="billing-credit-balance">
                                            <div className="credit-balance-header">
                                                <p>ResuCredits</p>
                                                <Link to="/plan"><button className="view-plans-button">View all plans</button></Link>
                                            </div>
                                            <p className="billing-credit-balance-number">{credits}</p>
                                            {/* Render subscription renew date */}
                                            {isSubscribed && (
                                                <div className="billing-subscription-edit">
                                                    <p>Renews on {subscriptionEnd}</p>
                                                    <button className="billing-cancel-subscription-btn" onClick={openModal1}>Cancel Subscription</button>
                                                </div>
                                            )}
                                            {subscriptionCancelled && (
                                                <div>{/* Render cancel confirmation & end date */}
                                                    <div className="billing-subscription-cancelled">Your subscription has been cancelled, enjoy unlimited credits until {subscriptionEnd}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='payment_list'>
                                            {displayedItems.map((item, index) => (
                                                <div key={index} className="payment_item">
                                                    <div className="item_price_row">
                                                        <div>{item.Item}</div>
                                                        <div>$ {item.price}</div>
                                                    </div>
                                                    <div className="timestamp_row">{item.time_stamp}</div>
                                                </div>
                                            ))}
                                        </div>
                                        {paymentHistory && paymentHistory.length > 0 && (
                                            <button onClick={() => setShowAll(!showAll)} className="toggle_button">
                                                {showAll ? 'Show less' : 'View more'}
                                            </button>
                                        )}
                                    </div>
                                )}
                                
                                <div className={`dimmer-cancel ${isModal1Open ? 'active' : ''}`}></div>
                                {isModal1Open && (
                                <div className="billing-modal-profile">
                                  {console.log("Modal is now open")} {/* Console log here */}
                                  <div className="billing-modal-profile-content">
                                    <img className='confirmation-icon'
                                        alt="confirmation logo icon"
                                        src="/img/logo-purple.png" />
                                      <h2>Cancel Subscription?</h2>
                                      <p>Are you sure you want to cancel your subscription? </p>
                                      <button className="billing-confirm-button-yes" onClick={handleCancelSubscription}>Yes</button>
                                      <button className="billing-confirm-button-no" onClick={closeModal1}>No</button>
                                  </div>
                                </div>
                                )}

                            </div>
                            
                        )}
                        
                    </div>

                    
                </div>
            </div >
        ) : (
            <div>Please log in first</div>
        ))

}
    
export default Dashboard;